var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex-column" },
    _vm._l(_vm.steps, function (step, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass: "step-item flex-row-center",
          class: { active: index === _vm.activeStep },
          on: {
            click: function ($event) {
              return _vm.selectStep(index)
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "step-number f-shrink",
              class: { active: index === _vm.activeStep },
            },
            [_vm._v(_vm._s(index + 1))]
          ),
          _c("div", { staticClass: "flex-column" }, [
            _c("div", { staticClass: "step-title" }, [
              _vm._v(_vm._s(step.title)),
            ]),
            _c("div", { staticClass: "step-des" }, [_vm._v(_vm._s(step.des))]),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }