<template>
  <div class="flex-column">
    <div
      v-for="(step, index) in steps"
      :key="index"
      class="step-item flex-row-center"
      :class="{ active: index === activeStep }"
      @click="selectStep(index)"
    >
      <div
        class="step-number f-shrink"
        :class="{ active: index === activeStep }"
      >{{ index + 1 }}</div>
      <div class="flex-column">
        <div class="step-title">{{ step.title }}</div>
        <div class="step-des">{{ step.des }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    steps: {
      type: Array,
      required: true,
    },
    activeStep: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    selectStep(index) {
      this.$emit("update:activeStep", index);
    },
  },
};
</script>

<style scoped>
.step-item {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 16px 12px;
  margin-bottom: 16px;
  border: 1px solid #e5e7eb;
  border-radius: 14px;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.step-item.active {
  background: #fdfdfd;
  border-radius: 14px;
  position: relative;
}

.step-item.active::before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border-radius: 14px;
  padding: 1px; /* 边框宽度 */
  background: linear-gradient(
    90deg,
    rgba(0, 137, 255, 1),
    rgba(110, 22, 209, 1)
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.step-number {
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #d4d4d8;
  border-radius: 50%;
  color: #d4d4d8;
  margin-right: 16px;
  font-family: PingFangSC-Regular;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
}

.step-number.active {
  position: relative;
  background: linear-gradient(90deg, #0089ff 0%, #6e16d1 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.step-number.active::before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border-radius: 14px;
  padding: 2px; /* 边框宽度 */
  background: linear-gradient(
    90deg,
    rgba(0, 137, 255, 1),
    rgba(110, 22, 209, 1)
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.step-title {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 24px;
  text-align: left;
  font-style: normal;
}
.step-des {
  font-family: PingFangSC, PingFang SC;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.26);
  line-height: 20px;
  text-align: left;
  font-style: normal;
  margin-top: 8px;
}
</style>
