<template>
  <div class="flex-row ">
    <div class="f-grow editor-scroll base-info-container">
      <BasicInfo
        ref="basicInfo"
        :value="item"
        @item-updated="handleItemUpdated"
        @update-background="updateBackgroundImageUrl"
        @update-videoDuration="updateVedioDuration"
        @remove="$emit('remove', item)"
      >
      </BasicInfo>
    </div>
    <div
      class="editor-scroll f-shrink"
      style="width:440px;padding: 16px;border-left:1px solid rgba(229,231,235,1);border-right:1px solid rgba(229,231,235,1);"
    >
      <div class="flex-row-center f-between">
        <div class="videoTitle">{{$t('basicInfo.sceneSettings')}}</div>
        <el-tooltip
          :content="$t('videoSetting.applyAll')"
          placement="top"
        >
          <el-switch
            v-model="currentSetupSync"
            @change="handleSetupSync"
          >
          </el-switch>
        </el-tooltip>
      </div>
      <div class="set-des">{{$t('videoSetting.subtitleTitleEffects')}}</div>
      <el-collapse
        v-model="activeNames"
        class="mt16"
      >
        <el-collapse-item name="1">
          <template slot="title">
            <i class="iconfont icon-peiyin"></i>
            <span>{{ $t('subtitle.subtitle') }}</span>
          </template>
          <SubtitleSettings
            ref="subtitleInfo"
            :value="item.audioTrackClipsBos"
            :background-image-url="backgroundImageUrl"
            :video-duration="videoDuration"
            :effectColorStyleList="effectColorStyleList"
            @item-updated="updatedAudio"
            @update-subtitle-style="updateSubtitleStyle"
          >
            <!-- <template v-slot:default="props">
              {{ props.value }}
            </template> -->
          </SubtitleSettings>
        </el-collapse-item>
        <el-collapse-item name="2">
          <template slot="title">
            <i class="iconfont icon-wenzi"></i>
            <span>{{ $t('title.title') }}</span>
          </template>
          <TitleSettings
            ref="titleInfo"
            :value="item.titleTrackClipsBo"
            :background-image-url="backgroundImageUrl"
            :effectColorStyleList="effectColorStyleList"
            @item-updated="updatedTitle"
            @update-subtitle-style="updateTitleStyle"
          >
          </TitleSettings>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div
      class="editor-scroll f-shrink"
      style="width: 293px;padding: 16px;"
    >
      <div class="videoTitle">{{$t('videoEditor.effectPreview')}}</div>
      <div class="set-des">{{$t('videoEditor.viewConfigEffect')}}</div>
      <div
        class="preview"
        :style="previewStyle"
      >
        <p :style="subtitlePreviewStyle">{{ getSubtitle }}</p>
        <p :style="titlePreviewStyle">{{ getTitle }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import BasicInfo from "./BasicInfo.vue";
import SubtitleSettings from "./SubtitleSettings.vue";
import TitleSettings from "./TitleSettings.vue";

export default {
  name: "TaskItem",
  components: {
    BasicInfo,
    SubtitleSettings,
    TitleSettings,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}), // 默认值为空对象
    },
    effectColorStyleList: {
      type: Array,
      default: () => [], // 默认值为空对象
    },
    setupSync: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    // 根据名称筛选部门树
    value(val) {
      this.item = {
        audioTrackClipsBos: {},
        titleTrackClipsBo: {},
        ...this.value,
      };
      this.$forceUpdate();
      this.changeBackground();
      this.changeDuration();
    },
    setupSync(val) {
      this.currentSetupSync = val; // 当 prop 变化时更新本地的 visible
    },
  },
  computed: {
    previewStyle() {
      return {
        backgroundImage: `url(${this.backgroundImageUrl})`,
        backgroundSize:
          "contain" /* 将背景图片调整到足够大，以使其在背景区域内完全显示 */,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat" /* 防止背景图片重复 */,
      };
    },
    getTitle() {
      return (
        (this.item.titleTrackClipsBo && this.item.titleTrackClipsBo.content) ||
        ""
      );
    },
    getSubtitle() {
      return (
        (this.item.audioTrackClipsBos &&
          this.item.audioTrackClipsBos.content) ||
        ""
      );
    },
  },
  data() {
    return {
      item: {
        audioTrackClipsBos: {},
        titleTrackClipsBo: {},
        ...this.value,
      },
      currentSetupSync: this.setupSync,
      backgroundImageUrl: "", // 初始化背景图片 URL
      videoDuration: 0, // 视频时长
      activeNames: "1",
      subtitlePreviewStyle: {}, //字幕的样式
      titlePreviewStyle: {}, //标题的样式
    };
  },
  methods: {
    updateSubtitleStyle(newStyle) {
      this.subtitlePreviewStyle = newStyle;
    },
    updateTitleStyle(newStyle) {
      this.titlePreviewStyle = newStyle;
    },
    handleItemUpdated(updatedItem) {
      this.$emit("item-updated", updatedItem);
    },
    // 配置是否应用到全部场景
    handleSetupSync(val) {
      this.$emit("update:setupSync", val);
      // console.log("setupSync", val);
      this.handleItemUpdated(this.item);
    },
    changeBackground() {
      let url = "";
      if (
        this.item.videoTrackClipsBos &&
        this.item.videoTrackClipsBos.length > 0
      ) {
        url = this.item.videoTrackClipsBos[0].firstFrameImgUrl;
      } else {
        url = "";
      }
      // console.log(
      //   "this.item.videoTrackClipsBos",
      //   JSON.stringify(this.item.videoTrackClipsBos)
      // );
      this.updateBackgroundImageUrl(url);
    },
    changeDuration() {
      let duration = 0;
      if (
        this.item.videoTrackClipsBos &&
        this.item.videoTrackClipsBos.length > 0
      ) {
        duration = this.item.videoTrackClipsBos.reduce((minOut, clip) => {
          return Math.min(minOut, clip.out);
        }, this.item.videoTrackClipsBos[0].out);
      }
      console.log("duration", duration);
      this.updateVedioDuration(duration);
    },
    updateBackgroundImageUrl(newUrl) {
      this.backgroundImageUrl = newUrl;
    },
    updateVedioDuration(duration) {
      this.videoDuration = duration;
    },
    updatedAudio(updatedItem) {
      this.item.audioTrackClipsBos = updatedItem;
      this.handleItemUpdated(this.item);
    },
    updatedTitle(updatedItem) {
      this.item.titleTrackClipsBo = updatedItem;
      this.handleItemUpdated(this.item);
    },
  },
};
</script>
<style lang="scss" scoped>
.el-collapse {
  border-top: none;
  border-bottom: none;
  i {
    font-size: 18px;
    color: #206bff;
    margin-right: 16px;
  }
}
.base-info-container {
  background: #f6f8fa;
  border-radius: 14px 0 0 14px;
  padding: 16px;
}
.preview {
  position: relative;
  width: 253px;
  height: 450px;
  background-color: #000;
  color: #fff;
  text-align: center;
  line-height: 30px;
  margin-top: 20px;
  overflow: hidden; /* 确保内容不会超出预览框 */
  border-radius: 10px;
}
* {
  max-width: 100%;
  box-sizing: border-box; /* 确保内边距和边框都计算在宽度内 */
}
</style>
