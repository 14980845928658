var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex-row " }, [
    _c(
      "div",
      { staticClass: "f-grow editor-scroll base-info-container" },
      [
        _c("BasicInfo", {
          ref: "basicInfo",
          attrs: { value: _vm.item },
          on: {
            "item-updated": _vm.handleItemUpdated,
            "update-background": _vm.updateBackgroundImageUrl,
            "update-videoDuration": _vm.updateVedioDuration,
            remove: function ($event) {
              return _vm.$emit("remove", _vm.item)
            },
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "editor-scroll f-shrink",
        staticStyle: {
          width: "440px",
          padding: "16px",
          "border-left": "1px solid rgba(229,231,235,1)",
          "border-right": "1px solid rgba(229,231,235,1)",
        },
      },
      [
        _c(
          "div",
          { staticClass: "flex-row-center f-between" },
          [
            _c("div", { staticClass: "videoTitle" }, [
              _vm._v(_vm._s(_vm.$t("basicInfo.sceneSettings"))),
            ]),
            _c(
              "el-tooltip",
              {
                attrs: {
                  content: _vm.$t("videoSetting.applyAll"),
                  placement: "top",
                },
              },
              [
                _c("el-switch", {
                  on: { change: _vm.handleSetupSync },
                  model: {
                    value: _vm.currentSetupSync,
                    callback: function ($$v) {
                      _vm.currentSetupSync = $$v
                    },
                    expression: "currentSetupSync",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "set-des" }, [
          _vm._v(_vm._s(_vm.$t("videoSetting.subtitleTitleEffects"))),
        ]),
        _c(
          "el-collapse",
          {
            staticClass: "mt16",
            model: {
              value: _vm.activeNames,
              callback: function ($$v) {
                _vm.activeNames = $$v
              },
              expression: "activeNames",
            },
          },
          [
            _c(
              "el-collapse-item",
              { attrs: { name: "1" } },
              [
                _c("template", { slot: "title" }, [
                  _c("i", { staticClass: "iconfont icon-peiyin" }),
                  _c("span", [_vm._v(_vm._s(_vm.$t("subtitle.subtitle")))]),
                ]),
                _c("SubtitleSettings", {
                  ref: "subtitleInfo",
                  attrs: {
                    value: _vm.item.audioTrackClipsBos,
                    "background-image-url": _vm.backgroundImageUrl,
                    "video-duration": _vm.videoDuration,
                    effectColorStyleList: _vm.effectColorStyleList,
                  },
                  on: {
                    "item-updated": _vm.updatedAudio,
                    "update-subtitle-style": _vm.updateSubtitleStyle,
                  },
                }),
              ],
              2
            ),
            _c(
              "el-collapse-item",
              { attrs: { name: "2" } },
              [
                _c("template", { slot: "title" }, [
                  _c("i", { staticClass: "iconfont icon-wenzi" }),
                  _c("span", [_vm._v(_vm._s(_vm.$t("title.title")))]),
                ]),
                _c("TitleSettings", {
                  ref: "titleInfo",
                  attrs: {
                    value: _vm.item.titleTrackClipsBo,
                    "background-image-url": _vm.backgroundImageUrl,
                    effectColorStyleList: _vm.effectColorStyleList,
                  },
                  on: {
                    "item-updated": _vm.updatedTitle,
                    "update-subtitle-style": _vm.updateTitleStyle,
                  },
                }),
              ],
              2
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "editor-scroll f-shrink",
        staticStyle: { width: "293px", padding: "16px" },
      },
      [
        _c("div", { staticClass: "videoTitle" }, [
          _vm._v(_vm._s(_vm.$t("videoEditor.effectPreview"))),
        ]),
        _c("div", { staticClass: "set-des" }, [
          _vm._v(_vm._s(_vm.$t("videoEditor.viewConfigEffect"))),
        ]),
        _c("div", { staticClass: "preview", style: _vm.previewStyle }, [
          _c("p", { style: _vm.subtitlePreviewStyle }, [
            _vm._v(_vm._s(_vm.getSubtitle)),
          ]),
          _c("p", { style: _vm.titlePreviewStyle }, [
            _vm._v(_vm._s(_vm.getTitle)),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }