var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex-column" },
    [
      _c(
        "div",
        { staticClass: "flex-row" },
        [
          _c(
            "el-form",
            {
              ref: "subtitleForm",
              staticClass: "f-grow",
              attrs: {
                model: _vm.settings,
                rules: _vm.rules,
                "label-width": "120px",
                "label-position": "top",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  attrs: { label: _vm.$t("subtitle.content"), prop: "content" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "textInput flex-column" },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "1000",
                          type: "textarea",
                          placeholder: _vm.$t("subtitle.tipContent"),
                        },
                        on: { input: _vm.contentChange },
                        model: {
                          value: _vm.settings.content,
                          callback: function ($$v) {
                            _vm.$set(_vm.settings, "content", $$v)
                          },
                          expression: "settings.content",
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "importText",
                          on: { click: _vm.importText },
                        },
                        [
                          _c("i", {
                            staticClass: "iconfont icon-a-dagoubeifen21",
                          }),
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("basicInfo.materialImport")) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "flexInputBtn" },
                        [
                          _c(
                            "el-tooltip",
                            {
                              attrs: { content: _vm.$t("subtitle.calculate") },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "btnBox",
                                  on: { click: _vm.calculate },
                                },
                                [
                                  _c("i", {
                                    staticClass: "iconfont icon-danyuanpeizhi1",
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("subtitle.calculateBtn")) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "btnBox",
                              on: { click: _vm.chooseLanguage },
                            },
                            [
                              _c("i", {
                                staticClass: "iconfont icon-danyuanpeizhi",
                              }),
                              _vm._v(
                                " " + _vm._s(_vm.$t("subtitle.translate")) + " "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "btnBox",
                              on: {
                                click: function ($event) {
                                  return _vm.rewrite("sc")
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "iconfont icon-zongjie" }),
                              _vm._v(
                                " " + _vm._s(_vm.$t("subtitle.sumup")) + " "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "btnBox",
                              on: {
                                click: function ($event) {
                                  return _vm.rewrite("sx")
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "iconfont icon-a-danyuanpeizhi4",
                              }),
                              _vm._v(_vm._s(_vm.$t("subtitle.abbreviation"))),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "btnBox",
                              on: {
                                click: function ($event) {
                                  return _vm.rewrite("kx")
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "iconfont icon-a-danyuanpeizhi5",
                              }),
                              _vm._v(_vm._s(_vm.$t("subtitle.expand"))),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "btnBox",
                              on: {
                                click: function ($event) {
                                  return _vm.rewrite("cx")
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "iconfont icon-a-danyuanpeizhi6",
                              }),
                              _vm._v(_vm._s(_vm.$t("subtitle.rewrite"))),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.total_seconds
                    ? _c("div", [
                        _vm.total_seconds > _vm.videoDuration
                          ? _c("div", { staticStyle: { color: "red" } }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("subtitle.tipCalculate1")) +
                                  _vm._s(_vm.total_seconds) +
                                  " " +
                                  _vm._s(_vm.$t("subtitle.tipCalculate3")) +
                                  " "
                              ),
                            ])
                          : _c("div", { staticStyle: { color: "green" } }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("subtitle.tipCalculate1")) +
                                  _vm._s(_vm.total_seconds) +
                                  " " +
                                  _vm._s(_vm.$t("subtitle.tipCalculate2")) +
                                  " "
                              ),
                            ]),
                      ])
                    : _vm._e(),
                ]
              ),
              _c("el-alert", {
                staticClass: "mt20",
                attrs: {
                  title: _vm.$t("subtitle.fitTip"),
                  type: "warning",
                  "show-icon": "",
                  closable: false,
                },
              }),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("subtitle.language"),
                    prop: "languageCode",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: _vm.$t("tip.select"),
                        filterable: "",
                        "default-first-option": true,
                      },
                      model: {
                        value: _vm.settings.languageCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.settings, "languageCode", $$v)
                        },
                        expression: "settings.languageCode",
                      },
                    },
                    _vm._l(_vm.azureVoiceList, function (dict) {
                      return _c("el-option", {
                        key: dict.name,
                        attrs: { label: dict.name, value: dict.name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("subtitle.enableDualSubtitles"),
                    prop: "isDoubleLanguage",
                  },
                },
                [
                  _c("el-switch", {
                    staticStyle: { margin: "10px" },
                    attrs: {
                      "active-text": _vm.$t("subtitle.open"),
                      "inactive-text": _vm.$t("subtitle.close"),
                    },
                    on: { change: _vm.handleItemUpdated },
                    model: {
                      value: _vm.settings.isDoubleLanguage,
                      callback: function ($$v) {
                        _vm.$set(_vm.settings, "isDoubleLanguage", $$v)
                      },
                      expression: "settings.isDoubleLanguage",
                    },
                  }),
                ],
                1
              ),
              _vm.settings.isDoubleLanguage
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("subtitle.secondSubtitleLanguage"),
                        prop: "languageSecond",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: _vm.$t("tip.select"),
                            filterable: "",
                            "default-first-option": true,
                          },
                          on: { change: _vm.languageSecondChange },
                          model: {
                            value: _vm.settings.languageSecond,
                            callback: function ($$v) {
                              _vm.$set(_vm.settings, "languageSecond", $$v)
                            },
                            expression: "settings.languageSecond",
                          },
                        },
                        _vm._l(_vm.languageList, function (dict) {
                          return _c("el-option", {
                            key: dict.bdCode,
                            attrs: { label: dict.language, value: dict.bdCode },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._l(_vm.settings.effects, function (effect, index) {
                return _c(
                  "div",
                  { key: "asr-" + index },
                  [
                    effect.type === "AI_ASR"
                      ? [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("subtitle.font"),
                                prop: "effects." + index + ".font",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    placeholder: _vm.$t("subtitle.tipFont"),
                                    "default-first-option": true,
                                  },
                                  on: { change: _vm.handleItemUpdated },
                                  model: {
                                    value: effect.font,
                                    callback: function ($$v) {
                                      _vm.$set(effect, "font", $$v)
                                    },
                                    expression: "effect.font",
                                  },
                                },
                                _vm._l(
                                  _vm.dict.type.font_typeface,
                                  function (dict) {
                                    return _c("el-option", {
                                      key: dict.value,
                                      attrs: {
                                        label: dict.label,
                                        value: dict.value,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("subtitle.fontSizeType"),
                                prop: "effects." + index + ".fontSizeType",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  on: {
                                    input: function ($event) {
                                      return _vm.fontSizeChange(effect)
                                    },
                                  },
                                  model: {
                                    value: effect.fontSizeType,
                                    callback: function ($$v) {
                                      _vm.$set(effect, "fontSizeType", $$v)
                                    },
                                    expression: "effect.fontSizeType",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    { attrs: { label: "small" } },
                                    [_vm._v(_vm._s(_vm.$t("subtitle.small")))]
                                  ),
                                  _c(
                                    "el-radio",
                                    { attrs: { label: "normal" } },
                                    [_vm._v(_vm._s(_vm.$t("subtitle.normal")))]
                                  ),
                                  _c("el-radio", { attrs: { label: "big" } }, [
                                    _vm._v(_vm._s(_vm.$t("subtitle.big"))),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("subtitle.spacing"),
                                prop: "effects." + index + ".spacing",
                              },
                            },
                            [
                              _c("el-input-number", {
                                attrs: { min: 0, max: 20 },
                                on: { change: _vm.handleItemUpdated },
                                model: {
                                  value: effect.spacing,
                                  callback: function ($$v) {
                                    _vm.$set(effect, "spacing", $$v)
                                  },
                                  expression: "effect.spacing",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("subtitle.fontColor"),
                                prop: "effects." + index + ".fontColor",
                              },
                            },
                            [
                              _c("el-color-picker", {
                                on: { change: _vm.handleItemUpdated },
                                model: {
                                  value: effect.fontColor,
                                  callback: function ($$v) {
                                    _vm.$set(effect, "fontColor", $$v)
                                  },
                                  expression: "effect.fontColor",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("subtitle.outline"),
                                prop: "effects." + index + ".outline",
                              },
                            },
                            [
                              _c("el-input-number", {
                                attrs: { min: 0, max: 10 },
                                on: { change: _vm.handleItemUpdated },
                                model: {
                                  value: effect.outline,
                                  callback: function ($$v) {
                                    _vm.$set(effect, "outline", $$v)
                                  },
                                  expression: "effect.outline",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("subtitle.outlineColour"),
                                prop: "effects." + index + ".outlineColour",
                              },
                            },
                            [
                              _c("el-color-picker", {
                                on: { change: _vm.handleItemUpdated },
                                model: {
                                  value: effect.outlineColour,
                                  callback: function ($$v) {
                                    _vm.$set(effect, "outlineColour", $$v)
                                  },
                                  expression: "effect.outlineColour",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("subtitle.position"),
                                prop: "effects." + index + ".position",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  on: {
                                    input: function ($event) {
                                      return _vm.logoChange(effect)
                                    },
                                  },
                                  model: {
                                    value: effect.position,
                                    callback: function ($$v) {
                                      _vm.$set(effect, "position", $$v)
                                    },
                                    expression: "effect.position",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: "top" } }, [
                                    _vm._v(_vm._s(_vm.$t("subtitle.top"))),
                                  ]),
                                  _c(
                                    "el-radio",
                                    { attrs: { label: "center" } },
                                    [_vm._v(_vm._s(_vm.$t("subtitle.center")))]
                                  ),
                                  _c(
                                    "el-radio",
                                    { attrs: { label: "bottom" } },
                                    [_vm._v(_vm._s(_vm.$t("subtitle.bottom")))]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("subtitle.fontFaces"),
                                prop: "effects." + index + ".fontFaces",
                              },
                            },
                            [
                              _c(
                                "el-checkbox-group",
                                {
                                  on: { change: _vm.changeFontFace },
                                  model: {
                                    value: effect.fontFaces,
                                    callback: function ($$v) {
                                      _vm.$set(effect, "fontFaces", $$v)
                                    },
                                    expression: "effect.fontFaces",
                                  },
                                },
                                [
                                  _c(
                                    "el-checkbox",
                                    { attrs: { label: "Italic" } },
                                    [_vm._v(_vm._s(_vm.$t("subtitle.italic")))]
                                  ),
                                  _c(
                                    "el-checkbox",
                                    { attrs: { label: "Bold" } },
                                    [_vm._v(_vm._s(_vm.$t("subtitle.blod")))]
                                  ),
                                  _c(
                                    "el-checkbox",
                                    { attrs: { label: "Underline" } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("subtitle.underline"))
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("subtitle.effectColorStyle"),
                                prop: "effects." + index + ".effectColorStyle",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "flex-row-center f-between" },
                                [
                                  _c("el-switch", {
                                    staticStyle: { margin: "10px" },
                                    on: { change: _vm.switchEffectUpdated },
                                    model: {
                                      value: effect.openEffectColorStyle,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          effect,
                                          "openEffectColorStyle",
                                          $$v
                                        )
                                      },
                                      expression: "effect.openEffectColorStyle",
                                    },
                                  }),
                                  effect.openEffectColorStyle
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              _vm.isExpansion = !_vm.isExpansion
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.isExpansion
                                                ? _vm.$t("collapse")
                                                : _vm.$t("expansion")
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              effect.openEffectColorStyle
                                ? _c(
                                    "div",
                                    { staticClass: "image-container" },
                                    _vm._l(_vm.styleList, function (item, in2) {
                                      return _c("img", {
                                        key: in2,
                                        class: {
                                          selected:
                                            effect.effectColorStyle ===
                                            item.effectColorStyle,
                                        },
                                        attrs: { src: item.imgUrl },
                                        on: {
                                          click: function ($event) {
                                            return _vm.selectEffectItem(item)
                                          },
                                        },
                                      })
                                    }),
                                    0
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                )
              }),
              _c(
                "div",
                { staticClass: "videoTitle flex-row-center" },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("subtitle.dubbingSettings")) + " "
                  ),
                  _c("el-switch", {
                    staticStyle: { margin: "10px" },
                    on: { change: _vm.handleItemUpdated },
                    model: {
                      value: _vm.settings.dubbing,
                      callback: function ($$v) {
                        _vm.$set(_vm.settings, "dubbing", $$v)
                      },
                      expression: "settings.dubbing",
                    },
                  }),
                ],
                1
              ),
              _vm.settings.dubbing
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("subtitle.voice"),
                            prop: "voice",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                "no-data-text": _vm.$t(
                                  "subtitle.tipLanguageFirst"
                                ),
                                placeholder: _vm.$t("subtitle.tipVoice"),
                              },
                              on: {
                                change: _vm.handleItemUpdated,
                                "visible-change": _vm.closeAudio,
                              },
                              model: {
                                value: _vm.settings.voice,
                                callback: function ($$v) {
                                  _vm.$set(_vm.settings, "voice", $$v)
                                },
                                expression: "settings.voice",
                              },
                            },
                            _vm._l(_vm.filteredVoiceList, function (voice) {
                              return _c(
                                "el-option",
                                {
                                  key: voice.shortName,
                                  attrs: {
                                    label: voice.localName,
                                    value: voice.shortName,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { float: "left" } },
                                    [_vm._v(_vm._s(voice.localName))]
                                  ),
                                  voice.url
                                    ? _c("el-button", {
                                        staticStyle: {
                                          float: "left",
                                          "margin-left": "10px",
                                        },
                                        attrs: {
                                          type: "text",
                                          icon: "el-icon-video-play",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.playAudio(voice.url)
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._l(_vm.settings.effects, function (effect, index) {
                        return _c(
                          "div",
                          { key: "volume-" + index },
                          [
                            effect.type === "Volume"
                              ? [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t("subtitle.gain"),
                                        prop: "effects." + index + ".gain",
                                      },
                                    },
                                    [
                                      _c("el-slider", {
                                        attrs: {
                                          step: 0.1,
                                          max: 10,
                                          min: 0,
                                          "show-input": "",
                                        },
                                        on: { change: _vm.handleItemUpdated },
                                        model: {
                                          value: effect.gain,
                                          callback: function ($$v) {
                                            _vm.$set(effect, "gain", $$v)
                                          },
                                          expression: "effect.gain",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        )
                      }),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "publicDialog",
          attrs: {
            width: "50%",
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _c("div", { staticClass: "headerFlex" }, [
              _c("div", { staticClass: "headerTitle" }, [
                _vm._v(_vm._s(_vm.$t("issueItem.translateTo"))),
              ]),
              _c("i", {
                staticClass: "el-icon-close",
                on: {
                  click: function ($event) {
                    _vm.dialogVisible = false
                  },
                },
              }),
            ]),
          ]),
          _c(
            "el-select",
            {
              staticStyle: { width: "100%" },
              attrs: {
                placeholder: _vm.$t("issueItem.translateLanguage"),
                filterable: "",
                clearable: "",
                "default-first-option": true,
              },
              model: {
                value: _vm.language,
                callback: function ($$v) {
                  _vm.language = $$v
                },
                expression: "language",
              },
            },
            _vm._l(_vm.languageList, function (dict) {
              return _c("el-option", {
                key: dict.bdCode,
                attrs: { label: dict.language, value: dict.bdCode },
              })
            }),
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "cancelBtn btnWidth120",
                  on: { click: _vm.handleCancel },
                },
                [_vm._v(_vm._s(_vm.$t("cancel")))]
              ),
              _c(
                "el-button",
                {
                  staticClass: "submitBtn btnWidth120",
                  attrs: { loading: _vm.loading },
                  on: { click: _vm.handleConfirm },
                },
                [_vm._v(_vm._s(_vm.$t("confirm")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("material-drawer", {
        ref: "materialDrawer",
        attrs: { visible: _vm.drawer },
        on: {
          "update:visible": function ($event) {
            _vm.drawer = $event
          },
          selectionConfirmed: _vm.selectionConfirmed,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }