<template>
  <div>
    <div class="flex-row">
      <el-form
        :model="settings"
        :rules="rules"
        ref="titleForm"
        label-width="120px"
        class="f-grow"
        label-position="top"
      >
        <!-- 字幕文本 -->
        <el-form-item
          :label="$t('title.title')"
          prop="content"
        >
          <el-input
            v-model="settings.content"
            maxlength="50"
            show-word-limit
            :placeholder="$t('title.tipTitle')"
            @input="handleItemUpdated"
          ></el-input>
        </el-form-item>

        <!-- 字幕字体 -->
        <el-form-item
          :label="$t('title.font')"
          prop="font"
        >
          <el-select
            v-model="settings.font"
            :placeholder="$t('title.tipFont')"
            @change="handleItemUpdated"
          >
            <el-option
              v-for="dict in dict.type.font_typeface"
              :key="dict.value"
              :label="dict.label"
              :value="dict.value"
            />
          </el-select>
        </el-form-item>

        <!-- 字幕字号 -->
        <!-- <el-form-item label="字号" prop="fontSize">
          <el-input-number v-model="settings.fontSize" :min="10" :max="100" @change="handleItemUpdated"></el-input-number>
        </el-form-item> -->
        <el-form-item
          :label="$t('title.fontSizeType')"
          prop="fontSizeType"
        >
          <el-radio-group
            v-model="settings.fontSizeType"
            @input="fontSizeChange"
          >
            <el-radio label="small">{{$t('title.small')}}</el-radio>
            <el-radio label="normal">{{$t('title.normal')}}</el-radio>
            <el-radio label="big">{{$t('title.big')}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- 字幕字间距 -->
        <el-form-item
          :label="$t('title.spacing')"
          prop="spacing"
        >
          <el-input-number
            v-model="settings.spacing"
            :min="0"
            :max="20"
            @change="handleItemUpdated"
          ></el-input-number>
        </el-form-item>

        <!-- 字幕颜色 -->
        <el-form-item
          :label="$t('title.fontColor')"
          prop="fontColor"
        >
          <el-color-picker
            v-model="settings.fontColor"
            @change="handleItemUpdated"
          ></el-color-picker>
        </el-form-item>

        <!-- 描边宽度 -->
        <el-form-item
          :label="$t('title.outline')"
          prop="outline"
        >
          <el-input-number
            v-model="settings.outline"
            :min="0"
            :max="10"
            @change="handleItemUpdated"
          ></el-input-number>
        </el-form-item>

        <!-- 描边颜色 -->
        <el-form-item
          :label="$t('title.outlineColour')"
          prop="outlineColour"
        >
          <el-color-picker
            v-model="settings.outlineColour"
            @change="handleItemUpdated"
          ></el-color-picker>
        </el-form-item>

        <!-- 字幕位置 -->
        <el-form-item
          :label="$t('title.alignment')"
          prop="alignment"
        >
          <el-radio-group
            v-model="settings.alignment"
            @input="alignmentUpdated"
          >
            <el-radio label="TopLeft">{{$t('title.left')}}</el-radio>
            <el-radio label="TopCenter">{{$t('title.center')}}</el-radio>
            <el-radio label="TopRight">{{$t('title.right')}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- <el-form-item label="Y" prop="y">
          <el-input-number v-model="settings.y" :min="0" :max="450" @change="handleItemUpdated"></el-input-number>
        </el-form-item> -->

        <!-- 字幕透明度 -->
        <el-form-item
          :label="$t('title.opacity')"
          prop="fontColorOpacity"
        >
          <el-slider
            v-model="settings.fontColorOpacity"
            :max="1"
            :min="0"
            :step="0.01"
            show-input
            @change="handleItemUpdated"
          ></el-slider>
        </el-form-item>

        <!-- 字幕样式 -->
        <el-form-item
          :label="$t('title.fontFaces')"
          prop="fontFaces"
        >
          <el-checkbox-group
            v-model="settings.fontFaces"
            @change="changeFontFace"
          >
            <el-checkbox label="Italic">{{$t('title.italic')}}</el-checkbox>
            <el-checkbox label="Bold">{{$t('title.blod')}}</el-checkbox>
            <el-checkbox label="Underline">{{$t('title.underline')}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <!-- 花字效果 -->
        <el-form-item
          :label="$t('title.effectColorStyle')"
          prop="effectColorStyle"
        >
          <div class="flex-row-center f-between">
            <el-switch
              v-model="settings.openEffectColorStyle"
              style="margin: 10px;"
              @change="switchEffectUpdated"
            ></el-switch>
            <el-button
              type="text"
              v-if="settings.openEffectColorStyle"
              @click="isExpansion = !isExpansion"
            >{{ isExpansion ? $t('collapse') : $t('expansion') }}</el-button>
          </div>
          <div
            class="image-container"
            v-if="settings.openEffectColorStyle"
          >
            <img
              v-for="(item, index) in styleList"
              :key="index"
              :src="item.imgUrl"
              :class="{ selected: settings.effectColorStyle === item.effectColorStyle }"
              @click="selectEffectItem(item)"
            />
          </div>
        </el-form-item>
      </el-form>
      <!-- 实时预览 -->
      <!-- <div style="width: 253px;margin-left: 10px;">
          {{$t('title.preview')}}
          <div class="preview" :style="previewStyle">
            <p :style="subtitlePreviewStyle">{{ settings.content }}</p>
          </div>
        </div> -->
    </div>
  </div>
</template>

<script>
export default {
  dicts: ["font_typeface"],
  props: {
    value: {
      type: Object,
      default: () => ({}), // 默认值为空对象
    },
    backgroundImageUrl: String,
    effectColorStyleList: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    // 根据名称筛选部门树
    value(val) {
      // console.log("-----title----", val);
      this.watchValue();
    },
    subtitlePreviewStyle(newStyle) {
      this.$emit("update-subtitle-style", newStyle);
    },
  },
  data() {
    return {
      settings: {
        content: "", //标题
        fontColorOpacity: 1, //透明度传的之后0-1
        x: 0,
        y: 0.1,
        outline: 0, //描边宽度
        outlineColour: "#ffffff", //描边颜色
        spacing: 0, //字间距
        // lineSpacing: '',//行间距
        fontSize: 53, //字体大小
        fontSizeType: "normal",
        font: "SimSun", //字体
        fontColor: "#ffffff", //字体颜色
        alignment: "TopCenter",
        position: "center",
        fontFaces: [], //字体样式
        fontFace: {
          Italic: false,
          Underline: false,
          Bold: false,
        },
        openEffectColorStyle: false,
        effectColorStyle: "", //花字
        // backColour:'',//花字中的背景色
        ...this.value,
      },
      rules: {
        content: [
          {
            required: true,
            message: this.$t("title.tipTitle"),
            trigger: ["blur", "change"],
          },
        ],
        font: [
          {
            required: true,
            message: this.$t("title.tipFont"),
            trigger: ["blur", "change"],
          },
        ],
        fontSizeType: [
          {
            required: true,
            message: this.$t("title.tipFontSizeType"),
            trigger: ["blur", "change"],
          },
        ],
        fontColor: [
          {
            required: true,
            message: this.$t("title.tipFontColor"),
            trigger: ["blur", "change"],
          },
        ],
        // outline: [
        //   { required: true, message: '请输入描边宽度', trigger: 'blur' }
        // ],
        // outlineColour: [
        //   { required: true, message: '请选择描边颜色', trigger: 'blur' }
        // ],
      },
      isExpansion: false, //是否展开花字
    };
  },
  computed: {
    subtitlePreviewStyle() {
      const settings = this.settings || {};
      const maxTop = 450;
      const alignment = settings.position || "center";
      return {
        fontFamily: settings.font || "SimSun",
        fontSize: `${settings.fontSize / 2.5 || 16}px`,
        letterSpacing: `${settings.spacing || 0}px`,
        color: settings.fontColor || "#ffffff",
        textShadow: `${settings.outline || 0}px ${settings.outline || 0}px ${
          settings.outlineColour || "#000000"
        }`,
        opacity: settings.fontColorOpacity,
        fontStyle: (settings.fontFaces || []).includes("Italic")
          ? "italic"
          : "normal",
        fontWeight: (settings.fontFaces || []).includes("Bold")
          ? "bold"
          : "normal",
        textDecoration: (settings.fontFaces || []).includes("Underline")
          ? "underline"
          : "none",
        position: "absolute",
        top: `${Math.min(settings.y * 450, maxTop)}px`,
        textAlign: alignment,
        left:
          alignment === "center" ? "50%" : alignment === "left" ? "0" : "auto",
        right: alignment === "right" ? "0" : "auto",
        transform:
          alignment === "center"
            ? "translateX(-50%)"
            : alignment === "right"
            ? "translateX(0)"
            : "none",
        padding: "0 10px", // 调整左右边距
        width: "250px",
      };
    },
    previewStyle() {
      return {
        backgroundImage: `url(${this.backgroundImageUrl})`,
        backgroundSize: "contain", //contain 、cover
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      };
    },
    styleList() {
      return this.isExpansion
        ? this.effectColorStyleList
        : this.effectColorStyleList.slice(0, 6);
    },
  },
  created() {
    this.watchValue();
    // this.handleItemUpdated()
  },
  methods: {
    watchValue() {
      this.settings = {
        content: "", //标题
        fontColorOpacity: 1, //透明度传的之后0-1
        x: 0,
        y: 0.1,
        outline: 0, //描边宽度
        outlineColour: "#ffffff", //描边颜色
        spacing: 0, //字间距
        // lineSpacing: '',//行间距
        fontSize: 53, //字体大小
        fontSizeType: "normal",
        font: "SimSun", //字体
        fontColor: "#ffffff", //字体颜色
        alignment: "TopCenter",
        fontFaces: [], //字体样式
        fontFace: {
          Italic: false,
          Underline: false,
          Bold: false,
        },
        openEffectColorStyle: false,
        effectColorStyle: "", //花字
        // backColour:'',//花字中的背景色
        ...this.value,
      };
      this.settings.fontColorOpacity = Number(this.settings.fontColorOpacity);
      if (this.settings.fontFace) {
        this.settings.fontFaces = Object.keys(this.settings.fontFace).filter(
          (key) => this.settings.fontFace[key]
        );
      }
      // console.log("-----title111----", this.settings);
      this.$forceUpdate();
      // this.handleItemUpdated()
    },
    //选中花字
    selectEffectItem(item) {
      this.settings.effectColorStyle = item.effectColorStyle;
      // this.settings.backColour = item.backColour
      // this.settings.outlineColour = item.outlineColour
      this.handleItemUpdated();
    },
    switchEffectUpdated() {
      // if(!this.settings.openEffectColorStyle){
      //   this.settings.effectColorStyle = ''
      // this.settings.backColour = ''
      // this.settings.outlineColour = '#ffffff'
      // }
      // this.handleItemUpdated()
    },
    //更新数据到上个页面
    handleItemUpdated() {
      this.$emit("item-updated", this.settings);
    },
    fontSizeChange() {
      if (this.settings.fontSizeType === "small") {
        this.settings.fontSize = 37;
      } else if (this.settings.fontSizeType === "big") {
        this.settings.fontSize = 70;
      } else {
        this.settings.fontSize = 53;
      }
      this.handleItemUpdated();
    },
    alignmentUpdated() {
      if (this.settings.alignment === "TopLeft") {
        this.settings.position = "left";
      } else if (this.settings.alignment === "TopRight") {
        this.settings.position = "right";
      } else {
        this.settings.position = "center";
      }
    },
    changeFontFace() {
      let fontFace = {};
      if (this.settings.fontFaces && Array.isArray(this.settings.fontFaces)) {
        fontFace.Bold = this.settings.fontFaces.includes("Bold");
        fontFace.Italic = this.settings.fontFaces.includes("Italic");
        fontFace.Underline = this.settings.fontFaces.includes("Underline");
      }
      this.settings.fontFace = fontFace;
      this.handleItemUpdated();
    },
    validate() {
      return new Promise((resolve, reject) => {
        if (this.settings && this.settings.content) {
          this.$refs["titleForm"].validate((valid) => {
            if (valid) {
              resolve();
            } else {
              reject(new Error("Validation failed"));
            }
          });
        } else {
          resolve();
        }
      });
    },
    getTempInfo() {
      return this.settings;
    },
  },
};
</script>

<style scoped>
.preview {
  position: relative;
  width: 253px;
  height: 450px;
  background-color: #000;
  color: #fff;
  text-align: center;
  line-height: 30px;
  margin-top: 20px;
}
.selected {
  border: 1px solid blue;
  border-radius: 5px;
}
.image-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* 图片间距 */
}

.image-container img {
  width: calc(50% - 5px); /* 两个图片一排，减去间距的一半 */
  box-sizing: border-box; /* 包含 padding 和 border */
  padding: 5px;
}
.el-form-item {
  margin-bottom: 6px;
}

::v-deep .el-form--label-top .el-form-item__label {
  padding: 0;
}
::v-deep .el-input-number__increase,
::v-deep .el-input-number__decrease {
  border-radius: 12px;
}
</style>
