var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      ref: "scrollList",
      staticClass: "scroll-list",
    },
    [
      !_vm.nowList
        ? _c(
            "vue-seamless-scroll",
            {
              ref: "scrollDom",
              staticClass: "scroll",
              attrs: {
                data: _vm.communityList,
                "class-option": _vm.defaultOption,
              },
            },
            [
              _c(
                "transition-group",
                { attrs: { name: "bounce", tag: "div" } },
                _vm._l(_vm.communityList, function (item, index) {
                  return _c("div", { key: index, staticClass: "flexItem" }, [
                    _c("img", {
                      staticStyle: {
                        width: "32px",
                        height: "32px",
                        "border-radius": "50%",
                        "margin-right": "10px",
                      },
                      attrs: { src: item.avatar || _vm.defaultAvatar },
                      on: { error: _vm.setDefaultAvatar },
                    }),
                    _c("div", { staticClass: "content line-2" }, [
                      _vm.timeAgo(item.createTime)
                        ? _c("div", { staticClass: "tag" }, [
                            _c("i", {
                              staticClass: "iconfont icon-gongzuotai2",
                            }),
                            _vm._v(_vm._s(_vm.timeAgo(item.createTime))),
                          ])
                        : _vm._e(),
                      item.paramData && item.paramData.platform
                        ? _c("span", { staticClass: "community-content mr8" }, [
                            _c("i", {
                              class: [
                                "iconfont",
                                "icon-" + item.paramData.platform + "-normal",
                              ],
                            }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.filterText(item.paramData.platform)
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      _c("span", {
                        staticClass: "community-content",
                        domProps: { innerHTML: _vm._s(item.content) },
                      }),
                    ]),
                  ])
                }),
                0
              ),
            ],
            1
          )
        : _c(
            "vue-seamless-scroll",
            {
              ref: "scrollDom",
              staticClass: "scroll",
              attrs: {
                data: _vm.communityList,
                "class-option": _vm.defaultOption,
              },
            },
            [
              _c(
                "transition-group",
                { attrs: { name: "bounce", tag: "div" } },
                _vm._l(_vm.communityList, function (item, index) {
                  return _c("div", { key: index, staticClass: "flexItem" }, [
                    _c("img", {
                      staticStyle: {
                        width: "32px",
                        height: "32px",
                        "border-radius": "50%",
                        "margin-right": "10px",
                      },
                      attrs: { src: item.avatar || _vm.defaultAvatar },
                      on: { error: _vm.setDefaultAvatar },
                    }),
                    _c("div", { staticClass: "content line-2" }, [
                      _c("div", { staticClass: "tag" }, [
                        _c("i", { staticClass: "iconfont icon-gongzuotai2" }),
                        _vm._v(_vm._s(_vm.timeAgo(item.createTime))),
                      ]),
                      item.paramData && item.paramData.platform
                        ? _c("span", { staticClass: "community-content mr8" }, [
                            _c("i", {
                              class: [
                                "iconfont",
                                "icon-" + item.paramData.platform + "-normal",
                              ],
                            }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.filterText(item.paramData.platform)
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      _c("span", {
                        staticClass: "community-content",
                        domProps: { innerHTML: _vm._s(item.content) },
                      }),
                    ]),
                  ])
                }),
                0
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }