<template>
  <!-- 粗剪 -->
  <div class="pruning">
    <div class="pruning-container">
      <video
        ref="video"
        :src="videoUrl"
        controls
        @timeupdate="updateCurrentTime"
        @play="onPlay"
        @pause="onPause"
      ></video>
      <div class="video-controls">
        <div class="flex-row-center ">
          <span class="video-time mr16">{{ secondsFormatTime(currentTime) }}</span>
          <input
            type="range"
            ref="progressBar"
            class="progress-bar"
            @click="seekVideo"
            :max="videoDuration"
            step="0.001"
            v-model="currentTime"
          />
          <span class="video-time ml16">{{ secondsFormatTime(videoDuration) }}</span>
        </div>
        <div class="flex-row-center mt16">
          <el-tooltip
            :content="$t('play')"
            v-if="!isPlaying"
          >
            <div
              class="video-play flex-center"
              @click="togglePlay"
            >
              <i class="iconfont icon-bofang1"></i>
            </div>
          </el-tooltip>
          <el-tooltip
            :content="$t('pause')"
            v-else
          >
            <div
              class="video-play flex-center"
              @click="togglePlay"
            >
              <i class="iconfont icon-zanting"></i>
            </div>
          </el-tooltip>
          <el-tooltip :content="$t('basicInfo.mark')">
            <div
              class="splitAudio flex-center"
              @click="setMarker"
            >
              <i class="iconfont icon-fenge"></i>
            </div>
          </el-tooltip>
        </div>
      </div>
      <div class="progress-container">
        <div class="progress-bar"></div>
        <!-- 添加刻度容器 -->
        <div class="ticks">
          <div
            v-for="tick in totalTicks"
            :key="tick"
            :style="{ left: (tick / videoDuration) * 100 + '%' }"
            class="tick"
          >
            <!-- 可选：显示每个刻度的时间 -->
            <span class="tick-time">{{ secondsFormatTime(tick) }}</span>
          </div>
        </div>
        <div class="markers">
          <!-- <div v-for="(marker, index) in markers" :key="index" :style="{ left: marker.out/videoDuration*100 + '%' }" class="marker"></div> -->
          <i
            v-for="(marker, index) in markers"
            :key="index"
            :style="{ left: 'calc(' + (marker.out / videoDuration * 100) + '% - 12px)'}"
            class="iconfont icon-fenge2 marker"
          ></i>
        </div>
      </div>
      <div class="mt16">
        <span class="pruning-title">{{$t('basicInfo.segment')}}</span>
        <span class="pruning-tip">{{$t('basicInfo.selectSegments')}}</span>
      </div>
      <div
        v-for="(marker, index) in markers"
        :key="index"
        class="pruning-item pointer"
        @click="selectedMarkerIndex = index"
      >
        <input
          type="radio"
          name="selectedMarker"
          :value="index"
          v-model="selectedMarkerIndex"
        />
        {{ formatTime(marker.in) }} - {{ formatTime(marker.out) }}
        <i
          class="iconfont icon-delete"
          v-if="index > 0"
          @click.stop="removeMarker(index)"
        ></i>
      </div>
    </div>
    <div class="drawer-footer">
      <el-button
        class="cancelBtn"
        @click="cancel"
      >{{ $t('cancel') }}</el-button>
      <el-button
        class="submitBtn"
        @click="confirm"
      >{{ $t('confirm') }}</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pruning",
  data() {
    return {
      videoUrl: "",
      oldMarkers: [],
      markers: [],
      videoDuration: 0,
      currentTime: 0,
      totalTicks: [], // 刻度列表
      isPlaying: false, // 用于跟踪视频播放状态
      selectedMarkerIndex: null, // 记录选中的 marker 索引
      selectedVideoLength: 0, // 记录选中的视频长度
    };
  },
  mounted() {
    this.$refs.video.addEventListener("loadedmetadata", () => {
      this.videoDuration = this.$refs.video.duration;
      this.markers[this.markers.length - 1].out = this.videoDuration;
      const tickCount = 10; // 刻度的数量
      this.totalTicks = Array.from(
        { length: tickCount },
        (_, index) => index * (this.videoDuration / tickCount)
      );
    });
  },
  methods: {
    init(item) {
      // console.log("init", item);
      this.videoUrl = item.mediaUrl;
      if (item.inOutList) {
        this.markers = item.inOutList;
      } else {
        this.markers = [
          { in: 0, out: item.out || item.videoOut, checked: true },
        ];
      }
      const selectedIndex = this.markers.findIndex((marker) => marker.checked);
      this.selectedMarkerIndex = selectedIndex !== -1 ? selectedIndex : null;
      this.oldMarkers = JSON.parse(JSON.stringify(this.markers)); // 深拷贝
    },
    togglePlay() {
      const videoElement = this.$refs.video;
      if (videoElement.paused) {
        videoElement.play(); // 播放视频
        this.isPlaying = true; // 更新播放状态
      } else {
        videoElement.pause(); // 暂停视频
        this.isPlaying = false; // 更新播放状态
      }
    },
    updateCurrentTime() {
      this.currentTime = this.$refs.video.currentTime;
      // console.log('this.currentTime',this.currentTime)
    },
    onPlay() {
      this.isPlaying = true; // 视频开始播放时更新状态
    },
    onPause() {
      this.isPlaying = false; // 视频暂停时更新状态
    },
    setMarker() {
      if (this.currentTime == 0) {
        this.$modal.msgError(this.$t("basicInfo.startPoint"));
        return;
      }
      if (this.currentTime == this.videoDuration) {
        this.$modal.msgError(this.$t("basicInfo.endPoint"));
        return;
      }
      for (let i = 0; i < this.markers.length; i++) {
        if (
          this.currentTime == this.markers[i].in ||
          this.currentTime == this.markers[i].out
        ) {
          this.$modal.msgError(this.$t("basicInfo.existingMark"));
          return;
        }
        if (
          this.currentTime > this.markers[i].in &&
          this.currentTime < this.markers[i].out
        ) {
          this.markers.splice(i + 1, 0, {
            in: this.currentTime,
            out: this.markers[i].out,
            checked: false,
          });
          this.markers[i].out = this.currentTime;
          return;
        }
      }
    },
    removeMarker(index) {
      if (index > 0) {
        this.markers.splice(index, 1);
        if (index === this.markers.length) {
          this.markers[this.markers.length - 1].out = this.videoDuration;
        }
      }
    },
    cancel() {
      this.$refs.video.pause(); // 停止视频播放
      this.$emit("cancel", this.oldMarkers);
    },
    confirm() {
      // const selectedSegments = this.markers.filter((marker) => marker.checked);
      // if (selectedSegments.length == 0) {
      //   this.$modal.msgError(this.$t("basicInfo.minSelect"));
      //   return;
      // }
      if (this.selectedMarkerIndex === null) {
        this.$modal.msgError(this.$t("basicInfo.minSelect"));
        return;
      }
      this.markers.forEach((marker, index) => {
        marker.checked = index === this.selectedMarkerIndex; // 只有选中的设为 true，其他设为 false

        if (marker.checked) {
          this.selectedVideoLength = (marker.out - marker.in).toFixed(2);
        }
      });
      this.$refs.video.pause(); // 停止视频播放
      // this.$emit("confirm", this.markers);
      this.$emit("confirm", this.markers, this.selectedVideoLength);
    },
    formatTime(seconds) {
      // const ms = (seconds % 1).toFixed(3).substring(2);
      const s = Math.floor(seconds) % 60;
      const m = Math.floor(seconds / 60) % 60;
      const h = Math.floor(seconds / 3600);
      return `${String(h).padStart(2, "0")}:${String(m).padStart(
        2,
        "0"
      )}:${String(s).padStart(2, "0")}`;
    },
    seekVideo(event) {
      const rect = event.target.getBoundingClientRect();
      const pos = (event.clientX - rect.left) / rect.width;
      this.$refs.video.currentTime = pos * this.videoDuration;
    },
  },
};
</script>

<style scoped>
.pruning {
  padding: 24px 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.pruning-container {
  flex-grow: 1; /* 让进度条容器撑开，剩余空间给它 */
  overflow-y: auto; /* 使内容区域可滚动 */
}
video {
  width: 100%;
  max-height: 400px;
  background: #000000;
  border-radius: 20px;
}
input[type="range"] {
  width: 100%;
}
.video-controls {
  background: #f6f8fa;
  border-radius: 20px;
  padding: 16px;
  margin-top: 24px;
}
.video-time {
  height: 20px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 20px;
  text-align: left;
  font-style: normal;
}
.progress-container {
  position: relative;
  width: 100%;
  height: 50px;
}
.progress-bar {
  height: 22px;
  background: #d3d8df;
  border-radius: 4px 4px 0px 0px;
}
.ticks {
  position: absolute;
  top: 14px;
  width: 100%;
  height: 100%;
}

.tick {
  position: absolute;
  /* bottom: 0; */
  width: 2px; /* 刻度的宽度 */
  height: 8px; /* 刻度的高度 */
  background-color: white; /* 刻度的颜色 */
}

.tick-time {
  position: absolute;
  bottom: -18px; /* 距离刻度的下方 */
  left: -12px; /* 刻度文字的偏移 */
  font-size: 10px;
  color: #888;
}
.markers {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.marker {
  position: absolute;
  color: #0156ff;
  font-size: 22px;
  top: -6px;
  bottom: 0;
  /* width: 2px; */
  /* background: red; */
  pointer-events: none;
}
.pruning-title {
  height: 20px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  line-height: 20px;
  text-align: left;
  font-style: normal;
  margin-right: 10px;
}
.pruning-tip {
  height: 17px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #ababab;
  line-height: 17px;
  text-align: left;
  font-style: normal;
}
.video-play {
  width: 49px;
  height: 28px;
  background: #e2eaf9;
  border-radius: 14px;
  font-size: 16px;
  color: #306cf4;
  cursor: pointer;
}
.splitAudio {
  width: 49px;
  height: 28px;
  background: #e2eaf9;
  border-radius: 14px;
  font-size: 18px;
  color: #306cf4;
  cursor: pointer;
  margin-left: 22px;
}
.pruning-item {
  margin: 12px 0;
}
.icon-delete {
  font-size: 15px;
  margin-left: 12px;
  color: #ff0f0f;
  cursor: pointer;
}
</style>
