<template>
  <div
    class="scroll-list"
    ref="scrollList"
    v-loading="loading"
  >
    <vue-seamless-scroll
      ref="scrollDom"
      :data="communityList"
      class="scroll"
      :class-option="defaultOption"
      v-if="!nowList"
    >
      <transition-group
        name="bounce"
        tag="div"
      >
        <div
          v-for="(item, index) in communityList"
          :key="index"
          class="flexItem"
        >
          <img
            :src="item.avatar || defaultAvatar"
            style="width: 32px; height: 32px; border-radius: 50%; margin-right: 10px;"
            @error="setDefaultAvatar"
          />
          <div class="content line-2">
            <div
              class="tag"
              v-if="timeAgo(item.createTime)"
            ><i class="iconfont icon-gongzuotai2"></i>{{ timeAgo(item.createTime) }}</div>
            <span
              v-if="item.paramData && item.paramData.platform"
              class="community-content mr8"
            >
              <i :class="['iconfont', 'icon-'+item.paramData.platform+'-normal']"></i>
              {{ filterText(item.paramData.platform) }}
            </span>
            <!-- <span
              class="community-content"
              style="color: #016FEE;"
            >&nbsp;&nbsp;{{ item.name }}</span> -->
            <!-- <span class="community-content">{{ item.content }}</span> -->
            <span
              class="community-content"
              v-html="item.content"
            ></span>
          </div>
        </div>
      </transition-group>
    </vue-seamless-scroll>
    <vue-seamless-scroll
      ref="scrollDom"
      :data="communityList"
      class="scroll"
      :class-option="defaultOption"
      v-else
    >
      <transition-group
        name="bounce"
        tag="div"
      >
        <div
          v-for="(item, index) in communityList"
          :key="index"
          class="flexItem"
        >
          <img
            :src="item.avatar || defaultAvatar"
            style="width: 32px; height: 32px; border-radius: 50%; margin-right: 10px;"
            @error="setDefaultAvatar"
          />
          <div class="content line-2">
            <div class="tag"><i class="iconfont icon-gongzuotai2"></i>{{ timeAgo(item.createTime) }}</div>
            <span
              v-if="item.paramData && item.paramData.platform"
              class="community-content mr8"
            >
              <i :class="['iconfont', 'icon-'+item.paramData.platform+'-normal']"></i>
              {{ filterText(item.paramData.platform) }}
            </span>
            <span
              class="community-content"
              v-html="item.content"
            ></span>
          </div>
        </div>
      </transition-group>
    </vue-seamless-scroll>
  </div>
</template>

<script>
import vueSeamlessScroll from "vue-seamless-scroll";
import { getCommunity } from "@/api/workbench";

export default {
  name: "scroll-list",
  dicts: ["social_platform"],
  components: {
    vueSeamlessScroll,
  },
  props: {
    // data: {
    //   type: Array,
    //   default: () => [],
    // }
  },
  data() {
    return {
      communityList: [],
      nowList: false,
      defaultOption: {
        step: 10, // 初始步长
        limitMoveNum: 4, // 开始无缝滚动的数据量
        hoverStop: true, // 是否开启鼠标悬停停止
        direction: 0, // 0 向下 1 向上 2 向左 3 向右
        openWatch: true, // 开启数据实时监控刷新 DOM
        singleHeight: 80, // 单步运动停止的高度
        singleWidth: 0, // 单步运动停止的宽度
        waitTime: 1000, // 单步运动停止的时间
      },
      interval: null,
      defaultAvatar: require("@/assets/images/avatar.png"), // 默认头像路径
    };
  },
  async created() {
    await this.getCommunityList();
  },
  mounted() {
    this.startChangingStep();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    setDefaultAvatar(event) {
      event.target.src = this.defaultAvatar;
    },
    async getCommunityList() {
      try {
        this.loading = true;
        const { rows } = await getCommunity({
          sortField: "create_time",
          sort: "desc",
          pageNum: 1,
          pageSize: 50,
        });
        console.log(rows);
        if (rows.length) {
          this.communityList = rows;
          // const newList = data.filter(item => item.time !== '刚刚');
          // const nowList = data.filter(item => item.time === '刚刚');
          // this.communityList = newList;
          // this.$nextTick(() => {
          //   // 在DOM更新后再设置为 nowList
          //   setTimeout(() => {
          //     this.communityList = [...nowList]; // 使用新数组来替换
          //     this.nowList = true;
          //     console.log(this.communityList);
          //   }, 2000);
          // });
        }
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    startChangingStep() {
      const changeInterval =
        Math.floor(Math.random() * (8000 - 4000 + 1)) + 4000;
      this.interval = setInterval(() => {
        this.defaultOption.waitTime = parseFloat(
          (Math.random() * (5000 - 1000) + 1000).toFixed(0)
        );
        this.$refs.scrollDom._startMove();
      }, changeInterval);
    },
    filterText(name) {
      const texts = {
        youtube: "Youtube",
        tiktok: "Tiktok",
        facebook: "Facebook",
        linkedin: "LinkedIn",
        twitter: "X/Twitter",
        instagram: "Instagram",
        reddit: "Reddit",
        gmb: "Google",
        pinterest: "Pinterest",
        telegram: "Telegram",
      };
      return texts[name] || "";
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll-list {
  height: 500px; /* 设置容器高度 */
  overflow: hidden; /* 隐藏溢出内容 */
  // display: flex;
  // flex-direction: column;
  // transition: transform 0.5s ease;
  .community-content {
    font-family: PingFang-SC, PingFang-SC;
    font-weight: 500;
    font-weight: 500;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.9);
    line-height: 22px;
    text-align: left;
    font-style: normal;
    .iconfont {
      font-size: 12px;
      margin-left: 5px;
    }
    .icon-quanbuzhanghao {
      color: #0156ff !important;
    }
    .icon-youtube-normal {
      color: #db0200;
    }
    .icon-tiktok-normal {
      background: #333333;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .icon-facebook-normal {
      color: #1a77f3;
    }
    .icon-linkedin-normal {
      color: #0474b3;
    }
    .icon-twitter-normal {
      color: #004da5;
    }
    .icon-instagram-normal {
      background: linear-gradient(221deg, #d82a81 0%, #ffcb5c 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .icon-reddit-normal {
      font-size: 19px;
      color: #ff4500;
    }
    .icon-gmb-normal {
      color: #0096ff;
    }
    .icon-pinterest-normal {
      color: #cc1f28;
    }
    .icon-telegram-normal {
      color: #0072ff;
    }
  }
  .flexItem {
    display: flex;
    align-items: center;
    min-height: 80px;
    height: 80px;
    border-bottom: 1px solid #dfdfdf;
    padding: 14px 16px;
    .content {
      .tag {
        display: inline-block;
        padding: 2px 5px;
        // height: 20px;
        // background: #5401FF;
        border: 1px solid #ec4c0c;
        // background: #d4e8fe;
        border-radius: 10px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 10px;
        // color: #FFFFFF;
        color: #ec4c0c;
        line-height: 14px;
        text-align: left;
        font-style: normal;
        margin-right: 11px;
        .iconfont {
          font-size: 10px;
          margin-right: 3px;
        }
      }
    }
  }
}
::v-deep a {
  cursor: pointer;
  text-decoration: underline !important;
}
::v-deep a:focus,
::v-deep a:hover {
  color: #0156ff !important;
}
</style>
